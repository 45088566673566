import { createStore } from 'vuex'
export default createStore({
  state () {
    return {
      defaultDomain: window.location.origin.indexOf("https") < 0 ? process.env.VUE_APP_DEFAULT_DOMAIN : window.location.origin.replace("www","m")
    }
  },
  getters: {
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions: {
  },
  modules: {
  }
})
